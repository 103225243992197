import React, { useCallback } from 'react';

import classNames from 'classnames';
import './Hamburger.scss';

export default function Hamburger({ isOpen, setIsOpen }) {
    const handleOnClick = useCallback(() => {
        let open = isOpen;
        setIsOpen(!open);
    });

    return (
        <button
            className={classNames({
                'navigation-hamburger': true,
                'is-open': isOpen,
                'is-closed': !isOpen,
            })}
            onClick={handleOnClick}
            title="Open main menu"
            data-tesid={'hamburger'}
        >
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
        </button>
    );
}
