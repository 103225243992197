/**
 * @namespace components
 */

import React from 'react';

import { currentLocale, isClient, dataLayer } from '../../../../../helpers';

import ProLink from '../../../../shared/ProLink/ProLink';

import svg from '../../../../../assets/images/logo/sony.svg';
import gif from '../../../../../assets/images/logo/sony.gif';

/**
 * @class NavigationLogo
 *
 * @description NavigationLogo render component
 *
 * @memberof components
 *
 *
 * @example
 * return (
 *   <NavigationLogo />
 * )
 */

const logoStyles = {
    maxWidth: '110px',
    width: '110px',
    height: '25px',
};

const handleItemClick = (e, NavigationLogo) => {
    const formattedMenuItemName = NavigationLogo.toLowerCase().replace(/\s+/g, '-');
    dataLayer.triggerEvent('header_logo_click', {
        event: 'navigation',
        category: 'navigation',
        action: 'click',
        label: formattedMenuItemName,
    });
};

const NavigationLogo = () => {
    const locale = currentLocale.get();
    return (
        <ProLink onClick={e => handleItemClick(e, 'logo-home')} to={`/${locale}/home`}>
            {isClient() ? (
                <picture>
                    <source type="image/svg+xml" srcSet={svg} />
                    <img src={gif} alt="Sony" style={logoStyles} />
                </picture>
            ) : (
                'Sony'
            )}
        </ProLink>
    );
};

export default NavigationLogo;
